import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import Thankyou from "../components/Checkout/Thankyou";
import { getThankyouDetails } from "../services/woocommerceServices/Orders";
import { HeadFC, navigate, Script } from "gatsby";
import PageLoader from "../components/common/Loader/PageLoader";
import { clearCart } from "../store/cartSlice";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { getQueryString } from "../utils/helper";
import GoogleAnalyticsEvent from "../components/GoogleAnalyticsEvent";

export default function thankyou() {
  const location = useLocation();
  const [records, setRecords] = useState<any>();
  const [woocom, setwoocom] = useState<any>();
  const [dynamicData, setDynamicData] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenpay = params.get("tokenpay");
    const idorder = params.get("idorder");
    dispatch(clearCart());
    getThankyouDetails(idorder, tokenpay)
      .then((data) => {
        console.log(data.woocom, "<<-- data.woocom")
        setRecords([data.paymentflow]);
        setwoocom([data.woocom]);
        setDynamicData({
          orderId: data.woocom.id,
          orderValue: data.woocom.total,
          shippingTotal: data.woocom.shipping_total,
          totalTax: data.woocom.total_tax,
          items: data.woocom.line_items.map((item: any) => ({
            item_id: item.id,
            item_name: item.name,
            price: item.price,
            quantity: item.quantity,
            
          })),
        });
      })
      .catch((err) => {
        console.log(err);
        // navigate("/404");
      });

    // ADD KLAVIYO SCRIPT
    setTimeout(() => {
      const script = document.createElement("script");
      script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VvNYhZ`; // Ensure the company ID is correct
      script.type = "text/javascript";
      script.defer = true;
      document.getElementById("klaviyo-container")?.appendChild(script);
      window._klOnsite = window._klOnsite || [];
      window._klOnsite.push(["openForm", "XGzGJW"]);
    }, 3000);

 const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'Analytics';
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: "12345",
            affiliation: "Online Store",
            value: 1.99,
            currency: "USD",
            items: [
              {
                item_name: "T-Shirt",
                item_id: "12345",
                price: 29.99,
                quantity: 2,
                category: "Apparel"
              }
            ]
          }
        });
      `;

      document.head.appendChild(script);
    
  }, []);

  return (
    <Layout>
      {dynamicData && <GoogleAnalyticsEvent dynamicData={dynamicData} />}
      {/* <DynamicScriptComponent totalCost={dynamicTotalCost} orderID={dynamicOrderID} /> */}
      {!records && (
        <div className=" min-h-[60vh] h-[60vh] flex justify-center items-center">
          <PageLoader />
        </div>
      )}
      {records && <Thankyou records={records} woocom={woocom} />}
    </Layout>
  );
}

// export const Head: HeadFC = ({ data }: any) => {
//   const tokenpay = getQueryString("tokenpay");
//   const idorder = getQueryString("idorder");
//   const [dynamicData, setDynamicData] = useState<any>(null);

//   // const
//   useEffect(() => {
//     getThankyouDetails(idorder, tokenpay)
//       .then((data) => {
//         console.log(data, "=====");
//         // setDynamicData(data.woocom);
//         setDynamicData({
//           orderId: data.woocom.id,
//           orderValue: data.woocom.total,
//           items: data.woocom.line_items.map((item: any) => ({
//             item_id: item.id,
//             item_name: item.name,
//             price: item.price,
//             quantity: item.quantity,
//           })),
//         });
//         // console.log(data.woocom.id, "data");

//         // setDynamicData({
//         //   woocom: data.woocom
//         // items: data.woocom.items.map((item: any) => ({
//         //   item_id: item.id,
//         //   item_name: item.name,
//         //   price: item.price,
//         //   quantity: item.quantity,
//         //   category: item.category || "Default Category", // Add a fallback if no category
//         // })),
//         // });
//       })
//       .catch(() => {});
//   }, []);

//   return (
//     <>
//       {dynamicData && (
//         <>
//           {/* Post Affiliate Tracker Script */}
//           {/* <script type="text/javascript" id="dynamic-affiliate" >
//             {`
//                PostAffTracker.setAccountId('default1');

//                var sale = PostAffTracker.createSale();
//                sale.setTotalCost('${dynamicData.orderValue}');
//                sale.setOrderID('${dynamicData.orderId}');
//                sale.setProductID('${dynamicData.items
//                  .map((item) => item.item_name)
//                  .join(", ")}');

//                PostAffTracker.register();
//              `}
//           </script> */}

//           {/* Google Analytics (dataLayer) Script */}
//           <script type="text/javascript"  id="Analytics" >
//             {`
//                window.dataLayer = window.dataLayer || [];
//                window.dataLayer.push({
//                  event: "purchase",
//                  ecommerce: {
//                    transaction_id: "${dynamicData.orderId}",
//                    affiliation: "Online Store",
//                    value: ${dynamicData.orderValue},
//                    currency: "USD",
//                    items: ${JSON.stringify(dynamicData.items)}
//                  }
//                });
//              `}
//           </script>
//         </>
//       )}
//     </>
//   );
// };
