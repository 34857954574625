import React, { useEffect } from "react";

const GoogleAnalyticsEvent = ({ dynamicData }) => {

  useEffect(() => {
    console.log(dynamicData, "<<-- dynamicData")
  }, [dynamicData])

  useEffect(() => {
    // Only append the script if dynamicData exists and contains the necessary information
    if (
      dynamicData &&
      dynamicData.orderId &&
      dynamicData.orderValue &&
      dynamicData.items
    ) {
      console.log(dynamicData, "<<-- dynamicData");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "Analytics";
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: ${dynamicData.orderId},
            affiliation: "Online Store",
            value: ${dynamicData.orderValue},
            shipping: ${dynamicData?.shippingTotal},
					  tax: ${dynamicData.totalTax},
					  currency: "USD",
            items: ${JSON.stringify(dynamicData.items)}
          }
        });
      `;

      // PostAffTracker Script
      if (dynamicData && dynamicData.orderId && dynamicData.orderValue) {
        setTimeout(() => {
          const postAffScript = document.createElement("script");
          postAffScript.type = "text/javascript";
          script.id = "Google";
          postAffScript.innerHTML = `
          PostAffTracker.setAccountId('default1');
          var sale = PostAffTracker.createSale();
          sale.setTotalCost('${dynamicData.orderValue}');
          sale.setOrderID('${dynamicData.orderId}'); 
          PostAffTracker.register();
        `;
          document.head.appendChild(postAffScript);
        }, 2000);
      }

      //   const testScript = document.createElement("script");
      //   testScript.type = "text/javascript";
      //   testScript.id = "TEST-script";
      //   testScript.innerHTML = `

      //   gtag("event", "purchase", {
      //       transaction_id: ${dynamicData.orderId},
      //       value: ${dynamicData.orderValue},
      //       tax: 0.00,
      //       shipping: 0.00,
      //       currency: "USD",
      //       items:  ${JSON.stringify(dynamicData.items)}
      //   });
      // `;

      document.head.appendChild(script);
      // document.head.appendChild(testScript);
    }
  }, [dynamicData]);

  return null; // This component doesn't render anything itself
};

export default GoogleAnalyticsEvent;
